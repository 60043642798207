import React from 'react';
import './style/List.css';
import { Container, Row, Col } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

export const Research = () => {
    const data = [
        {
            title: 'Fuzzy Logic based Visual Impairment Level Identification System for Toddlers and preschoolers',
            des: `A prototype that represents an Android gaming app designed for children, utilizing Artificial
            Intelligence-based Fuzzy Logic system. Its can detect nearsightedness and colorblindness, common optical
            defects in toddlers and preschoolers, enabling parents to identify them conveniently at home using a single
            app.`,
            time: 'Jan 2023 - Dec 2023'
        }
    ];

    return (
        <section className="experience" id="research">
            <Container fluid={true} className="px-4">
                <Row>
                    <Col size={12}>
                        <h2>Research</h2>
                        <br />
                    </Col>
                </Row>
                <br />
                <div className="list">
                    {data.map((item, index) => (
                        <AnimationOnScroll key={`animation-${index}`} animateIn="animate__slideInUp" animateOnce delay={index % 2 === 0 ? 100 : 150}>
                            <div className="item" key={index}>
                                <h3>{item.title}</h3>
                                <p>{item.des}</p>
                                {item.time && <span className="time">{item.time}</span>}
                            </div>
                        </AnimationOnScroll>
                    ))}
                </div>
            </Container>
        </section>
    );
};