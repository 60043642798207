// import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

import java from '../assets/img/java.png';
import c from '../assets/img/c++.png';
import html from '../assets/img/html.png';
import css from '../assets/img/css.png';
import re from '../assets/img/react.png';
import db from '../assets/img/database.png';
import as from '../assets/img/as.png';

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const items = [
        {
            imgSrc: java,
            altText: "Java",
            links: [
                { url: "https://www.w3schools.com/java/", text: "Java" }
            ]
        },
        {
            imgSrc: c,
            altText: "C++",
            links: [
                { url: "https://www.w3schools.com/cpp/", text: "C++" }
            ]
        },
        {
            imgSrc: html,
            altText: "HTML",
            links: [
                { url: "https://www.w3schools.com/html/", text: "HTML" }
            ]
        },
        {
            imgSrc: css,
            altText: "CSS",
            links: [
                { url: "https://www.w3schools.com/css/", text: "CSS" }
            ]
        },
        {
            imgSrc: re,
            altText: "React",
            links: [
                { url: "https://reactnative.dev/", text: "React Native" }
            ]
        },
        {
            imgSrc: db,
            altText: "DB",
            links: [
                { url: "https://www.mysql.com/", text: "MySQL" },
                { url: "https://firebase.google.com/docs", text: "Firebase" }
            ]
        },
        {
            imgSrc: as,
            altText: "Android Studio",
            links: [
                { url: "https://developer.android.com/studio", text: "Android Studio" }
            ]
        },
    ];

    const CarouselItem = ({ imgSrc, altText, links }) => (
        <div className="item">
            <img src={imgSrc} alt={altText} />
            <div className="overlay">
                {links.map((link, index) => (
                    <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className={`txt ${index > 0 ? 'second' : ''}`}>
                        {link.text}
                    </a>
                ))}
            </div>
        </div>
    );

    return (
        <section className="skill" id="skills">
            <Container fluid={true}>
                <Row>
                    <Col size={12}>
                        <h2>Skills</h2>
                        <br />
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlay
                                autoPlaySpeed={2500}
                                centerMode={false}
                                className="owl-carousel owl-theme skill-slider"
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={responsive}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={2}
                                swipeable
                            >
                                {items.map((item, index) => (
                                    <CarouselItem key={index} {...item} />
                                ))}
                            </Carousel>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}