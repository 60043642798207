import React from 'react';
import './style/List.css';
import { Container, Row, Col } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

export const Awards = () => {
    const data = [
        {
            title: `VC's List For Outstanding Academic Performance | YGPA : 3.92`,
            des: `Faculty of Computing | General Sir John Kotelawela Defence University, Sri Lanka`,
            time: '2022'
        },
        {
            title: `Dean's List For Outstanding Academic Performance | YGPA : 3.62`,
            des: `Faculty of Computing | General Sir John Kotelawela Defence University, Sri Lanka`,
            time: '2021'
        },
        {
            title: `Dean's List For Outstanding Academic Performance | YGPA : 3.84`,
            des: `Faculty of Computing | General Sir John Kotelawela Defence University, Sri Lanka`,
            time: '2020'
        }
    ];

    return (
        <section className="experience" id="awards">
            <Container fluid={true} className="px-4">
                <Row>
                    <Col size={12}>
                        <h2>Awards</h2>
                        <br />
                    </Col>
                </Row>
                <br />
                <div className="list">
                    {data.map((item, index) => (
                        <AnimationOnScroll key={`animation-${index}`} animateIn="animate__slideInUp" animateOnce delay={index % 2 === 0 ? 100 : 150}>
                            <div className="item" key={index}>
                                <h3>{item.title}</h3>
                                <p>{item.des}</p>
                                {item.time && <span className="time">{item.time}</span>}
                            </div>
                        </AnimationOnScroll>
                    ))}
                </div>
            </Container>
        </section>
    );
};