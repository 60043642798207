import React from 'react';
import './style/List.css';
import { Container, Row, Col } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

export const Pub = () => {
    const data = [
        {
            des: `Sammani, MHK. and DU. Vidanagama (2023). "Paper: A Methodical Comparative Study for Identify a
            Solution through Artificial Intelligence for Detection of Visual Impairment in Toddlers Preschoolers".
            In: `,
            des1: `3rd Student Symposium, General Sir John Kotelawala Defence University, Sri Lanka, `,
            des2: `p. 6.`
        },
        {
            des: `Sammani, MHK. et al. (Sept. 2023a). "Paper: Fuzzy Logic based Visual Impairment Level Identification
            System for Toddlers and Preschoolers". In: `,
            des1: `16th International Research Conference, General Sir John
            Kotelawela Defence University, Sri Lanka, `,
            des2: `p. 6.`
        },
        {
            des: `Sammani, MHK. et al. (Sept. 2023a). "Paper: Usability of the Fuzzy Logic based Visual Impairment Level Identification System
            for Toddlers and Preschoolers". In: `,
            des1: `8th International Conference on Advances in Technology and
            Computing (ICATC), University of Kelaniya, Sri Lanka, `,
            des2: `p. 6.`
        }
    ];

    return (
        <section className="experience" id="pub">
            <Container fluid={true} className="px-4">
                <Row>
                    <Col size={12}>
                        <h2>Publications</h2>
                        <br />
                    </Col>
                </Row>
                <br />
                <div className="list">
                    {data.map((item, index) => (
                        <AnimationOnScroll key={`animation-${index}`} animateIn="animate__slideInUp" animateOnce delay={index % 2 === 0 ? 100 : 150}>
                            <div className="item" key={index}>
                                <p style={{ display: 'inline' }}>{item.des}</p>
                                <p style={{ display: 'inline', fontStyle: 'italic', fontWeight: 400 }}>{item.des1}</p>
                                <p style={{ display: 'inline' }}>{item.des2}</p>
                            </div>
                        </AnimationOnScroll>
                    ))}
                </div>
            </Container>
        </section>
    );
};